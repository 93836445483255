import { Component, HostListener, isDevMode, OnInit } from '@angular/core';
import { ConnectionService } from 'ng-connection-service';
import { ViewportScroller } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { ScormWindowService } from './services/scorm.window.service';
import { UserService } from './services/userService';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-root',
  // templateUrl: './app.component.html',
  template: `
  <router-outlet><router-outlet>
  <div *ngIf="!isConnected">
  <div style="position:absolute;height:100px;width:100vw; bottom:0; background:#dc3545;">
    <div style="color: #fff; padding:15px; text-align:center; vertical-align:middle; margin: auto">
    <h4><span  style="position: relative; left: 12px;"> No estas conectada a internet. </span></h4>
    </div>
  </div>
  </div>
  `,
})
export class AppComponent implements OnInit {

  isConnected = true;
  networkStatus = 'ONLINE';
  langs = ['en', 'es'];

  constructor(
    private connectionService: ConnectionService,
    private viewportScroller: ViewportScroller,
    private scormWindowService: ScormWindowService,
    private router: Router,
    // private location: Location,
    private userService: UserService,
    private translateService: TranslateService
  ) {
    this.translateService.setDefaultLang('es');
   }


  public useLanguage(lang: string): void {
    this.translateService.setDefaultLang(lang);
  }
  // removeHeader() {
  //   const titlee = this.location.prepareExternalUrl(this.location.path());
  //   if (titlee.includes('login')) {
  //     return false;
  //   } else {
  //     return true;
  //   }
  // }

  ngOnInit() {

    this.translateService.setDefaultLang('es');

    /*
    if (this.langs.indexOf(browserlang) > -1) {
      this.translateService.setDefaultLang(browserlang);
      localStorage.setItem('appLang',browserlang)
    } else {
      this.translateService.setDefaultLang('es');
      localStorage.setItem('appLang','es')
    }
    */
    /** scroll to top When route navigation start */
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.viewportScroller.scrollToPosition([0, 0]);
      }
    });

    /** Network check */
    this.connectionService.monitor().subscribe(isConnected => {
      this.isConnected = isConnected;
      if (this.isConnected) {
        this.networkStatus = 'ONLINE';
      } else {
        this.networkStatus = 'OFFLINE';
      }

    });
  }

  @HostListener('window:beforeunload', ['$event'])
  refreshwindow(event) {
    if (!isDevMode) {
      event.returnValue = 'Are you sure you want to leave this page?';
    }
  }

  @HostListener('window:unload')
  unload() {
    if (!isDevMode) {
      if (this.scormWindowService.isCourseWindowOpen()) {
        this.scormWindowService.close(true);
      } else {
        this.userService.removeSessionDetails();
        this.router.navigate(['/login']);
      }
    }
  }

}
